import React, { createContext, useContext, useState } from 'react'
// import { SharedContext } from '../SharedContextProvider/SharedContextProvider'
import axios from 'axios'
import { CONSTANTS } from '../../constants'
import { handleResponseErrors } from '../SharedContextProvider/error/handleResponseErrors'
import { SharedContext } from '../SharedContextProvider/SharedContextProvider'

export const AuthContext = createContext()

const AuthContextProvider = ({ children }) => {
    const { navigate } = useContext(SharedContext)


    const [currentStep, setCurrentStep] = useState(null)
    const [userType, setUserType] = useState(null)

    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const [createdUser, setCreatedUser] = useState({
        id: null,
        email: null,
        userType: null,
        loginToken: null,
        rawPwd: null,
    })


    async function handleSignup(e) {
        const userDetails = {
            prefix: e.prefix ?? "",
            userType: userType ?? "",
            firstName: e.firstName ?? "",
            lastName: e.lastName ?? "",
            email: e.email ?? "",
            gender: e.gender ?? "",
            phone: e.phone ?? "",
            password: e.password ?? "",
            whereDidYouHearAboutUs: e.whereDidYouHearAboutUs ?? "",
        }

        try {
            setLoading(true)
            const response = await axios.post(`${CONSTANTS.BACKEND_URL}/users/create`, userDetails)

            if (response.status === 201) {
                setLoading(false)

                const id = response.data.id
                const email = response.data.email
                const userType = response.data.user_type

                setCreatedUser((prev) => ({
                    ...prev,
                    id: id,
                    email: email,
                    userType: userType,
                    rawPwd: userDetails.password,
                }))

                navigate(`/signup?type=${userType}&step=2`)
            }
        } catch (error) {
            setLoading(false)

            setMessage(handleResponseErrors(error))
        }
    }

    async function handleVerifyEmail(e) {
        if (e.otp_value) {
            try {
                setLoading(true)
                const response = await axios.post(`${CONSTANTS.BACKEND_URL}/users/verify-account`, {
                    userId: createdUser.id,
                    userType: createdUser.userType,
                    rawPwd: createdUser.rawPwd,
                    otp: e.otp_value,
                    email: createdUser.email
                })

                if (response.status === 200 && response.data.verified === true) {
                    setLoading(false)

                    const loginToken = response.data.login_token

                    localStorage.setItem("NMH_LOGIN_TOKEN", loginToken)

                    navigate(`/signup?type=${userType}&step=3`)
                }
            } catch (error) {
                setLoading(false)
                setMessage(handleResponseErrors(error))
            }
        }
    }

    console.log(`${process.env.REACT_APP_NMH_DOCTOR_URL}/auth/${localStorage.getItem("NMH_LOGIN_TOKEN")}`)

    function handleNavigateToDashboard() {

        if (createdUser.userType === "DOCTOR") {

            process.env.NODE_ENV === "production"
                ? window.open(`${process.env.REACT_APP_NMH_DOCTOR_URL}/auth/${localStorage.getItem("NMH_LOGIN_TOKEN")}`, "_blank")
                : window.open(`http://localhost:3001/auth/${localStorage.getItem("NMH_LOGIN_TOKEN")}`, "_blank")



            setCreatedUser((prev) => ({
                ...prev,
                id: null,
                email: null,
                userType: null,
                loginToken: null,
            }))

            navigate(`/signup?type=${userType}&step=1`)

            setTimeout(() => {
                localStorage.removeItem("NMH_LOGIN_TOKEN")
            }, 5000);
        }

        if (createdUser.userType === "PATIENT") {
            window.open(`patients.${CONSTANTS.FRONTEND_URL}/auth/${localStorage.getItem("NMH_LOGIN_TOKEN")}`, "_blank")

            setCreatedUser((prev) => ({
                id: null,
                email: null,
                userType: null,
                loginToken: null,
            }))

            navigate(`/signup?type=${userType}&step=1`)

            setTimeout(() => {
                localStorage.removeItem("NMH_LOGIN_TOKEN")
            }, 5000);
        }
    }

    async function handleDoctorLogin(e) {

        if (e.email === '' || e.password === "") {
            setMessage('All fields are required')
            return
        } else {
            try {
                setLoading(true)
                const response = await axios.post(`${CONSTANTS.BACKEND_URL}/doctors/login`, {
                    email: e.email,
                    password: e.password,
                })

                if (response.status === 200) {
                    setLoading(false)

                    const token = response.data.token
                    // window.open(`doctors.${CONSTANTS.FRONTEND_URL}/auth/${localStorage.getItem("NMH_LOGIN_TOKEN")}`, "_blank")
                    window.location = `doctors.${CONSTANTS.FRONTEND_URL}/auth/${token}`

                }
            } catch (error) {
                setLoading(false)

                setMessage(handleResponseErrors(error))
            }
        }

    }

    const values = {
        userType, setUserType,
        // 
        message,
        loading,
        // 
        currentStep,
        setCurrentStep,
        handleSignup,
        handleVerifyEmail,
        handleNavigateToDashboard,
        createdUser,
        // 
        handleDoctorLogin
    }
    return (
        <AuthContext.Provider value={values}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider

const CONSTANTS = {
  FRONTEND_URL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_NMH_CLIENT_URL
      : process.env.REACT_APP_LOCAL_FRONTEND_URL,
  BACKEND_URL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_NMH_API_URL
      : process.env.REACT_APP_LOCAL_BACKEND_URL,
};

module.exports = { CONSTANTS };

import React from 'react'

const Blog = () => {

    // const categories = []
    return (
        <div className='blog_wrapper'>
            <div className="inner__">
                <div className="head_row">
                    <h1 className="">Blog</h1>
                    <div className="categories_row">
                        <div className="category"></div>
                    </div>
                </div>
                <div className="body"></div>
            </div>
        </div>
    )
}

export default Blog

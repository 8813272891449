import React, { useState } from 'react'
import './Navbar.css'
// import logo1 from '../../Assets/logo/nmh-1.png'
import logo2 from '../../Assets/logo/nmh-2.png'
// import { AppThemeContext } from '../../Context/AppThemeContextProvider/AppThemeContextProvider'
import {
    // MoonOutlined, SunOutlined,
    LoginOutlined,
    // LogoutOutlined,
    MenuOutlined
} from '@ant-design/icons';
// import { Switch, } from 'antd';
import { Link } from 'react-router-dom';
import { Drawer } from 'antd';
// import { DownOutlined } from '@ant-design/icons';
// import { Dropdown, Space } from 'antd';

// const planItems = [
//     {
//         label: (
//             <Link to='/plans#personalPlan' className="navlink_ btnType7">Personal Plan  </Link>
//         ),
//         key: '0',
//     },
//     {
//         label: (
//             <Link to='/plans#familyPlan' className="navlink_ btnType7">Family Plan  </Link>
//         ),
//         key: '1',
//     },
//     {
//         label: (
//             <Link to='/plans#companyPlan' className="navlink_ btnType7">Company Plan  </Link>
//         ),
//         key: '2',
//     },
// ];

// const servicesItems = [
//     {
//         label: (<Link to='/schedule-appoitment' className="navlink_ btnType7">Schedule Appointment  </Link>),
//         key: '3',
//     },
//     {
//         label: (<Link to='/get-nurse' className="navlink_ btnType7">Find a Nurse  </Link>),
//         key: '4',
//     },
//     {
//         label: (<Link to='/find-pharmacy' className="navlink_ btnType7">Find Nearby pharmacy  </Link>),
//         key: '5',
//     },
// ];

const Navbar = () => {
    // const { toggleTheme } = useContext(AppThemeContext)

    const [open_sideMenu, setOpen_sideMenu] = useState(false);
    const showDrawer_sideMenu = () => {
        setOpen_sideMenu(!open_sideMenu);
    };
    const onClose_sideMenu = () => {
        setOpen_sideMenu(false);
    };

    return (
        <div className='Navbar'>
            <div className="inner">
                <div className="left">
                    <Link to='/' >
                        <img src={logo2} alt="" />
                    </Link>
                </div>
                <div className="right">

                    <nav className="navlink_grp">
                        <ul>
                            <li >   <Link to='/' className="navlink_ btnType7"> Home </Link>  </li>

                            <li > <Link to='/' className="navlink_ btnType7">About  </Link>   </li>
                            <li > <Link to='/' className="navlink_ btnType7">Health Plans  </Link>   </li>
                            <li > <Link to='/' className="navlink_ btnType7">Services  </Link>   </li>

                            {/* <li >
                                <Dropdown
                                    menu={{
                                        planItems,
                                    }}
                                    overlayStyle={{
                                        zIndex: "9999"
                                    }}
                                >
                                    <span className="navlink_ btnType7">
                                        <Space>
                                            Health Plans
                                            <DownOutlined />
                                        </Space>
                                    </span>
                                </Dropdown>
                            </li> */}

                            {/* <li >
                                <Dropdown
                                    menu={{
                                        servicesItems,
                                    }}
                                    overlayStyle={{
                                        zIndex: "9999"
                                    }}
                                >
                                    <span className="navlink_ btnType7">
                                        <Space>
                                            Services
                                            <DownOutlined />
                                        </Space>
                                    </span>
                                </Dropdown>
                            </li> */}

                            <li > <Link to='/' className="navlink_ btnType7"> Blog </Link>  </li>
                        </ul>
                    </nav>

                    {/* <Switch
                        checkedChildren={<SunOutlined />}
                        unCheckedChildren={<MoonOutlined />}
                        defaultChecked
                        onClick={toggleTheme}
                    />
                    <span className='dividerVertical'></span> */}
                    <div className="action-grp">
                        <Link to='/login' className='login-btn btnType1'> <LoginOutlined />  Login</Link>
                        <Link to='/signup?type=PATIENT&step=1' className='get-started-btn btnType2'>Get Started</Link>
                        {/* <button className='login-btn btnType1'> <LogoutOutlined />  Logout</button> */}
                    </div>

                    <MenuOutlined className='navbarToggleBtn btnType1' onClick={() => showDrawer_sideMenu()} />
                </div>
            </div>

            <SideMenu open_sideMenu={open_sideMenu} onClose_sideMenu={onClose_sideMenu} />


        </div>
    )
}

export default Navbar


const SideMenu = ({ open_sideMenu, onClose_sideMenu }) => {


    return (
        <Drawer
            // title="Basic Drawer"
            closeIcon={false}

            onClose={onClose_sideMenu}
            open={open_sideMenu}

        >
            <br />
            <br />
            <br />
            <br />
            <br />


            <div className="SideMenu_wrapper">
                <nav
                    className="navlink_grp"
                >
                    <ul
                    >
                        <li >   <Link to='/' className="navlink_ btnType7_"> Home </Link>  </li>
                        <li > <Link to='/' className="navlink_ btnType7_">About  </Link>   </li>
                        <li > <Link to='/' className="navlink_ btnType7_">Health Plans  </Link>   </li>
                        <li > <Link to='/' className="navlink_ btnType7_">Services  </Link>   </li>
                        <li > <Link to='/' className="navlink_ btnType7_"> Blog </Link>  </li>
                    </ul>
                </nav>
                <div
                    className="action-grp"
                >
                    <Link to='/login' className='login-btn btnType1'> <LoginOutlined />  Login</Link>
                    <Link to='/signup' className='get-started-btn btnType2'>Get Started</Link>
                </div>
            </div>
        </Drawer>

    )
}
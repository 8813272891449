import React from 'react'
import { Link } from 'react-router-dom'
import { Input, Button } from 'antd'
import { SearchOutlined, CloseOutlined } from '@ant-design/icons'
import { motion } from 'framer-motion';

const FrontBannerWithSearch = () => {

    const suggestions = [
        "family medicine",
        "pediatrics",
        "top hospital",
        "telehealth",
        "covid-19",
        "orthopedic sugery",
    ]

    return (
        <motion.div
            initial='hidden'
            animate='visible'
            transition={{
                delayChildren: 1,
                staggerChildren: 0.05
            }}
            className='FrontBannerWithSearch'
        >
            <div className="inner_">
                <div className="inner_1">
                    <h1 className="bigText ">Feel <span className="emphasis">confident</span> about  quality <span className="emphasis">healthCare</span> </h1>
                    <p className="subText">
                        At Neomerit Metro Health, we take the guess work of finding the right doctors, hospitals and care for you and your family.
                    </p>
                    <div className="action_area">
                        <Link to="/" className='btnType2'> Get Started Today </Link>
                    </div>
                </div>

                <div className="inner_2 serach_area__ borderType2">
                    <div className="input_area__">
                        <div className="grid_x2">
                            <Input type='text' prefix={<SearchOutlined />} placeholder='Search Doctors, Conditions or Procedures' size='large' />
                            <Input type='text' placeholder='Set your location' size='large' />
                        </div>
                        <Button icon={<SearchOutlined />} size='large' className='btnType3' />
                    </div>

                    <div className="suggestions_area__">
                        <p className="title_">You may be looking for:</p>
                        <div className="sugestions_grp">
                            {
                                suggestions.map((suggestion) => (
                                    <button className="suggestion" key={suggestion}> <span className="label">{suggestion}</span>  <span className="remove_suggestion">{<CloseOutlined />}</span> </button>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className="inner_3">
                    {/* inner 3 */}
                </div>
            </div>
        </motion.div>
    )
}

export default FrontBannerWithSearch

import React, { useContext, useEffect, } from 'react'
import './SignUp.css'
import { UserOutlined, MailOutlined, DashboardFilled } from '@ant-design/icons'
import { Button, Form, Input, Select } from 'antd'
import { motion } from "framer-motion"
import { AuthContext } from '../../Context/AuthContextProvider/AuthContextProvider'
import { SharedContext } from '../../Context/SharedContextProvider/SharedContextProvider'
const { Option } = Select;


const SignUp = () => {
    const searchParams = new URL(window.location).searchParams
    let paramsObject = {};
    for (let pair of searchParams.entries()) {
        paramsObject[pair[0]] = pair[1];
    }
    const { type, step } = paramsObject
    // ----------------------------------

    const {
        userType, setUserType,
        currentStep,
        setCurrentStep,
        // handleDetailsChange,
        handleSignup,
        handlePatientSignup,
        handleVerifyEmail,
        handleNavigateToDashboard,
        message, loading,
        createdUser
    } = useContext(AuthContext)

    // --------------------

    useEffect(() => {
        setUserType(type)
    }, [setUserType, type,])

    useEffect(() => {
        setCurrentStep(Number(step))
    }, [setCurrentStep, step])

    return (

        <motion.div
            className='SignUp_wrapper'

            initial={{ opacity: 0, translateY: "-25px", }}
            animate={{ opacity: 1, translateY: "0", }}
            transition={{ duration: 0.5 }}
        >
            <div className="steps_container">
                <div className="inner_2">
                    <div className={currentStep === 1 ? "steps_grp_current" : "steps_grp"}>
                        <div className="line__"></div>
                        <div className="icon_box">
                            <UserOutlined />
                        </div>
                        <div className="label_grp">
                            <div className="big_text">Your details</div>
                            <div className="small_text">complete the fields provided</div>
                        </div>
                    </div>
                    <div className={currentStep === 2 ? "steps_grp_current" : "steps_grp"}>
                        <div className="icon_box">
                            <MailOutlined />
                        </div>
                        <div className="label_grp">
                            <div className="big_text">verify your email</div>
                            <div className="small_text">enter verification code sent to your email</div>
                        </div>
                    </div>
                    <div className={currentStep === 3 ? "steps_grp_current" : "steps_grp"}>
                        <div className="icon_box">
                            <DashboardFilled />
                        </div>
                        <div className="label_grp">
                            <div className="big_text">welcome to neomerit metro health</div>
                            <div className="small_text">get up and running in 2minutes</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="form_container">
                <div className="inner_form_container">
                    {currentStep === 1 && userType === 'DOCTOR' && <Details loading={loading} message={message} userType={userType} setUserType={setUserType} handleSignup={handleSignup} />}
                    {currentStep === 1 && userType === 'PATIENT' && <PatientDetails loading={loading} message={message} userType={userType} setUserType={setUserType} handlePatientSignup={handlePatientSignup} />}

                    {currentStep === 2 && <Otp createdUser={createdUser} handleVerifyEmail={handleVerifyEmail} />}
                    {currentStep === 3 && <Welcome handleNavigateToDashboard={handleNavigateToDashboard} />}
                </div>
            </div>
        </motion.div>


    )
}

export default SignUp


const Details = ({ loading, message, userType, setUserType, handleSignup, }) => {
    const { navigate } = useContext(SharedContext)

    return (
        <motion.div
            className="details_wrapper"

            initial={{ opacity: 0, translateY: "-25px", }}
            animate={{ opacity: 1, translateY: "0", }}
            transition={{ duration: 0.5 }}
        >
            <h1 className="row_1">
                SignUp
            </h1>
            <p className="subtext">Complete the fields provided below</p>

            <div className="USER_TYPE_SELECTOR">
                <button onClick={() => navigate(`signup?type=PATIENT&step=1`)}>
                    Patient
                </button>
                <button onClick={() => navigate(`signup?type=DOCTOR&step=1`)} className={userType === 'DOCTOR' && 'selectedType'}>
                    Doctor / Nurse
                </button>
            </div>

            <Form
                name='signupForm'
                layout="vertical"

                onFinish={handleSignup}
            >
                <div className="form_grp">
                    <div className="message" style={{ color: "red" }}>
                        {message}
                    </div>

                    <div className="grid_x2">
                        <Form.Item
                            label="Prefix?"
                            name='prefix'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select placeholder="prefix?">
                                <Option value="Dr."> Dr. (Medical Doctor)   </Option>
                                <Option value="RN."> RN. (Registered Nurse) </Option>
                            </Select>
                        </Form.Item>
                    </div>



                    <div className="grid_x2">
                        <Form.Item label="First name"
                            required
                            tooltip="This is a required field"
                            name='firstName'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}

                        >
                            <Input placeholder="First name" />
                        </Form.Item>
                        <Form.Item
                            name='lastName'
                            label="Last name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            tooltip="This is a required field"
                        >
                            <Input placeholder="Last name" />
                        </Form.Item>
                    </div>

                    <div className="grid_x2">
                        <Form.Item
                            label="Gender?"
                            name='gender'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select placeholder="Gender?">
                                <Option value="Male"> Male </Option>
                                <Option value="Female"> Female </Option>
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="grid_x2">
                        <Form.Item
                            name='email'
                            label="Email"
                            required
                            tooltip="This is a required field"

                            rules={[
                                {
                                    type: 'email',
                                    required: true
                                },
                            ]}
                        >
                            <Input placeholder="example@mail.com" />
                        </Form.Item>
                        <Form.Item
                            label="Phone"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            tooltip="This is a required field"
                            name='phone'
                        >
                            <Input placeholder="Phone" />
                        </Form.Item>
                    </div>

                    <div className="grid_x2">
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            tooltip="This is a required field"
                        >
                            <Input type='password' placeholder="Password" name='password' />
                        </Form.Item>

                        {/* Field */}
                        <Form.Item
                            label="Confirm Password"
                            name="password2"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The new password that you entered do not match!'));
                                    },
                                }),
                            ]}
                            tooltip="This is a required field"
                        >
                            <Input type='password' placeholder="ConfirmPassword" name='password2' />
                        </Form.Item>
                    </div>

                    <div className="grid_x2">
                        <Form.Item
                            label="Where did you hear about us?"
                            name='whereDidYouHearAboutUs'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select placeholder="Where did you hear about us? (optional)" >
                                <Option value="TV"> TV </Option>
                                <Option value="Radio"> Radio </Option>
                                <Option value="Social Media"> Social Media </Option>
                                <Option value="Internet"> Internet </Option>
                                <Option value="Blog"> Blog </Option>
                                <Option value="Others">Others</Option>
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="btn_grp">
                        <Button
                            htmlType='submit'
                            type="primary"
                            size='large'
                            className='btnType3'
                            // onClick={handleSignup}
                            style={{ justifySelf: "flex-end" }}
                            // onLoad={true}
                            loading={loading}
                        >
                            {loading ? "Submitting..." : "Continue"}
                        </Button>
                    </div>
                </div>

            </Form>
        </motion.div>
    )
}

const PatientDetails = ({ loading, message, userType, setUserType, handleSignup, }) => {
    const { navigate } = useContext(SharedContext)


    return (
        <motion.div
            className="details_wrapper"

            initial={{ opacity: 0, translateY: "-25px", }}
            animate={{ opacity: 1, translateY: "0", }}
            transition={{ duration: 0.5 }}
        >
            <h1 className="row_1">
                SignUp
            </h1>
            <p className="subtext">Complete the fields provided below</p>

            <div className="USER_TYPE_SELECTOR">
                <button onClick={() => navigate(`signup?type=PATIENT&step=1`)} className={userType === 'PATIENT' ? 'selectedType' : ""}>
                    Patient
                </button>
                <button onClick={() => navigate(`signup?type=DOCTOR&step=1`)} >
                    Doctor / Nurse
                </button>
            </div>

            <Form
                name='patientsignupForm'
                layout="vertical"
                onFinish={handleSignup}
            >
                <div className="form_grp">
                    <div className="message" style={{ color: "red" }}>
                        {message}
                    </div>

                    <div className="grid_x2">
                        <Form.Item label="First name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            tooltip="This is a required field" name='firstName'
                        >
                            <Input placeholder="First name" />
                        </Form.Item>
                        <Form.Item
                            name='lastName'
                            label="Last name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            tooltip="This is a required field"
                        >
                            <Input placeholder="Last name" />
                        </Form.Item>
                    </div>

                    <div className="grid_x2">
                        <Form.Item
                            label="Gender?"
                            name='gender'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select placeholder="Gender?">
                                <Option value="Male"> Male </Option>
                                <Option value="Female"> Female </Option>
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="grid_x2">
                        <Form.Item
                            name='email'
                            label="Email"
                            required
                            tooltip="This is a required field"

                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                },
                            ]}
                        >
                            <Input placeholder="example@mail.com" />
                        </Form.Item>
                        <Form.Item
                            label="Phone"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            tooltip="This is a required field"
                            name='phone'
                        >
                            <Input placeholder="Phone" />
                        </Form.Item>
                    </div>

                    <div className="grid_x2">
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            tooltip="This is a required field"
                        >
                            <Input type='password' placeholder="Password" name='password' />
                        </Form.Item>

                        {/* Field */}
                        <Form.Item
                            label="Confirm Password"
                            name="password2"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The new password that you entered do not match!'));
                                    },
                                }),
                            ]}
                            tooltip="This is a required field"
                        >
                            <Input type='password' placeholder="ConfirmPassword" name='password2' />
                        </Form.Item>
                    </div>

                    <div className="grid_x2">
                        <Form.Item
                            label="Where did you hear about us?"
                            name='whereDidYouHearAboutUs'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select placeholder="Where did you hear about us? (optional)" >
                                <Option value="TV"> TV </Option>
                                <Option value="Radio"> Radio </Option>
                                <Option value="Social Media"> Social Media </Option>
                                <Option value="Internet"> Internet </Option>
                                <Option value="Blog"> Blog </Option>
                                <Option value="Others">Others</Option>
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="btn_grp">
                        <Button
                            htmlType='submit'
                            type="primary"
                            size='large'
                            className='btnType3'
                            // onClick={handleSignup}
                            style={{ justifySelf: "flex-end" }}
                            // onLoad={true}
                            loading={loading}
                        >
                            {loading ? "Submitting..." : "Continue"}
                        </Button>
                    </div>
                </div>

            </Form>
        </motion.div>
    )
}

const Otp = ({ createdUser, handleVerifyEmail, handleGoBack }) => {


    return (
        <motion.div
            className="otp_wrapper"

            initial={{ opacity: 0, scale: 0.5, }}

            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
        >
            <h1 className="row_1">
                Verify Your Email
            </h1>
            <p className="subtext">
                Please enter 4-digit code sent to <span style={{ textTransform: "lowercase", fontWeight: "500" }}> {createdUser.email} </span>
            </p>

            <Form
                name='otp'
                layout="vertical"
                onFinish={handleVerifyEmail}
            >
                <div className="form_grp">
                    <Form.Item
                        // label="Success"
                        hasFeedback
                        validateStatus="success"
                        required
                        name='otp_value'
                    >
                        <Input.OTP size='large' autoFocus length={4} />
                    </Form.Item>

                    <div className="btn_grp">
                        {/* <Button type="primary" size='large' className='btnType6' onClick={() => handleGoBack(1)}>Previous</Button> */}
                        <Button htmlType='submit' type="primary" size='large' className='btnType3' >Continue</Button>
                    </div>
                </div>
            </Form>


        </motion.div>
    )
}


const Welcome = ({ handleNavigateToDashboard, handleGoBack }) => {


    return (
        <motion.div
            className="otp_wrapper"

        // initial={{ opacity: 0, scale: 0.5, }}
        // animate={{ opacity: 1, scale: 1 }}
        // transition={{ duration: 0.5 }}
        >
            <h1 className="row_1">
                Welcome to Neomerit Metro Health
            </h1>
            <p className="subtext">
                Get Up And Running In 2minutes
            </p>

            <div className="form_grp">
                <Button type="primary" size='large' className='btnType3' onClick={handleNavigateToDashboard}>Go To Dashboard</Button>
            </div>


        </motion.div>
    )
}
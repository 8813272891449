import { useEffect } from 'react';

const AutomaticScrollToTop = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return null; // This component doesn't render anything
};



export default AutomaticScrollToTop

import { Collapse } from 'antd';

const Faq = () => {
    const text = `
  answer to faq
`;
    const items = [
        {
            key: '1',
            label: 'FAQ 1',
            children: <p>{text}</p>,
        },
        {
            key: '2',
            label: 'FAQ 2',
            children: <p>{text}</p>,
        },
        {
            key: '3',
            label: 'FAQ 3',
            children: <p>{text}</p>,
        },
    ];

    return (
        <div className='Faq'>
            <div className="inner__ borderType2">
                <div className="head__ headType1">
                    Frequently Asked Questions (FAQ's)
                </div>
                <div className="body__ ">
                    <Collapse
                        accordion
                        items={items}
                        bordered={false}
                    // ghost

                    />
                </div>
            </div>
        </div>
    )
}

export default Faq

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
//
import AppThemeContextProvider from "./Context/AppThemeContextProvider/AppThemeContextProvider";
import SharedContextProvider from "./Context/SharedContextProvider/SharedContextProvider";
import AuthContextProvider from "./Context/AuthContextProvider/AuthContextProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppThemeContextProvider>
        <SharedContextProvider>
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </SharedContextProvider>
      </AppThemeContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

import React, { useContext } from "react";
import "./singleLogin.css";
import { motion } from "framer-motion";
import { Button, Form, Input } from "antd";
import { AuthContext } from "../../Context/AuthContextProvider/AuthContextProvider";

const DoctorLogin = () => {
  const { handleDoctorLogin, message, loading } = useContext(AuthContext);

  return (
    <div className="single_login_wrapper">
      <motion.div
        className="login_inner_wrapper"
        initial={{ opacity: 0, translateY: "-25px" }}
        animate={{ opacity: 1, translateY: "0" }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="row_1">Doctor/Nurse Login</h1>
        <p className="subtext">Please provide your credentials</p>
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>

        <Form name="loginForm" layout="vertical" onFinish={handleDoctorLogin}>
          <div className="form_grp">
            <div className="grid_x2">
              <Form.Item
                name="email"
                label="Email"
                required
                tooltip="This is a required field"
                rules={[
                  {
                    type: "email",
                    required: true,
                  },
                ]}
              >
                <Input placeholder="example@mail.com" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                  },
                ]}
                tooltip="This is a required field"
              >
                <Input type="password" placeholder="Password" name="password" />
              </Form.Item>
            </div>

            <div className="btn_grp">
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                className="btnType3"
                // onClick={handleSignup}
                style={{ justifySelf: "flex-end" }}
                // onLoad={true}
                loading={loading}
              >
                {loading ? "Loggin In..." : "Login"}
              </Button>
            </div>
          </div>
        </Form>
      </motion.div>
    </div>
  );
};

export default DoctorLogin;

import React, { useContext } from 'react'
import './home.css'
import { motion } from "framer-motion";
import { AppThemeContext } from '../../Context/AppThemeContextProvider/AppThemeContextProvider'
import MeetOurSpecialists from './MeetOurSpecialists/MeetOurSpecialists'
import FrontBannerWithSearch from './FrontBannerWithSearch/FrontBannerWithSearch'

// 
import YouDontHaveAnAccountYet from '../YouDontHaveAnAccountYet/YouDontHaveAnAccountYet'
import WhyYouShouldChooseUs from './WhyYouShouldChooseUs/WhyYouShouldChooseUs'
import ServicesGrp from './ServicesGrp/ServicesGrp'
import Faq from './Faq/Faq'

const Home = () => {
    const { isDarkMode, } = useContext(AppThemeContext)

    return (
        <motion.div
            initial='hidden'
            animate='visible'
            transition={{
                delayChildren: 1,
                staggerChildren: 0.05
            }}
            className={`homw_wrapper ${isDarkMode ? 'app-dark' : 'app-light'}`}
        >
            <FrontBannerWithSearch />

            <ServicesGrp />

            {/* why you should choose us */}
            <WhyYouShouldChooseUs />

            <MeetOurSpecialists />

            <YouDontHaveAnAccountYet />

            <Faq />
        </motion.div>
    )
}

export default Home

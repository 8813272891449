import React, { useEffect, useState } from 'react'
import './SearchPage.css'
import { Input, Button } from 'antd';
import { SearchOutlined, SortAscendingOutlined, SortDescendingOutlined, CaretRightOutlined, PlusOutlined } from '@ant-design/icons';
import avatar from '../../Assets/MeetOurSpecialists/doc2.jpg'
import location_icon from '../../Assets/search-page/location-pin-svgrepo-com.svg'
import { Tooltip } from 'antd';


const SearchPage = () => {


    const searchParams = new URL(window.location).searchParams
    let paramsObject = {};
    for (let pair of searchParams.entries()) {
        paramsObject[pair[0]] = pair[1];
    }
    const type = paramsObject?.type
    // -------------------------------

    const [isAtTop, setIsAtTop] = useState(true);

    const handleScroll = () => {
        if (window.scrollY === 0) {
            setIsAtTop(true);
        } else {
            setIsAtTop(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    let array = [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12]




    return (
        <div className='SearchPage'>
            <div className="inner__">

                <div className={`${isAtTop ? 'search_bar' : 'search_bar_2'}`}>
                    {
                        type === 'get-nurse'
                            ? <h2 className='headType1'> Get A Nurse Near You </h2>
                            : <h2 className='headType1'>    Find Nearby Pharmacy </h2>
                    }

                    <div className="inner_2 serach_area__ borderType2">
                        <div className="input_area__">
                            <div className="grid_x2">
                                <Input type='text' prefix={<SearchOutlined />} placeholder='Search Nurse' size='large' autoFocus />
                                <Input type='text' prefix={<SearchOutlined />} placeholder='Set your location' size='large' />
                                <button size='large' className='btnType5' >Use Current Location</button>
                            </div>
                            <Button icon={<SearchOutlined />} size='large' className='btnType3' />
                        </div>
                    </div>
                </div>

                <div className="search_body">

                    <div className="filter_container_ borderType2"> filter_container_ </div>

                    <div className="results_container_ borderType2__">
                        <div className="controls_container_">
                            <div className="total_"> 5 Results</div>
                            <div className="sort_by">
                                Sort
                                <Button type='text' icon={<SortAscendingOutlined />} />
                                <Button type='text' icon={<SortDescendingOutlined />} />
                            </div>
                        </div>

                        <div className="result_grp">
                            {
                                array.map((item, i) => (
                                    <Tooltip title="Victor Ohms" key={i}>
                                        <div className="result bgGreyLight1_">
                                            <div className="image_conatiner">
                                                <img src={avatar} alt="" />
                                            </div>
                                            <div className="details_container">
                                                <div className="row_1">
                                                    <div className="left">Victor Ohms</div>
                                                    <div className="right"></div>
                                                </div>

                                                <div className="row_2">
                                                    Dermatologist
                                                </div>

                                                <div className="row_3">
                                                    <img src={location_icon} alt="" />
                                                    No 11, Gwarimpa Avenue
                                                </div>
                                            </div>
                                            <button className="action_btn btnType4">
                                                Connect
                                                <CaretRightOutlined />
                                            </button>
                                        </div>
                                    </Tooltip>
                                ))
                            }
                            <div className="see_more btnType5">See More <PlusOutlined /> </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}



export default SearchPage

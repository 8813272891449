import React from 'react'
import './ScrollTotop.css'
import { ToTopOutlined } from '@ant-design/icons'

const ScrollTotop = () => {

    const scrollToTop = () => {
        // window.scrollTo(0, 0);
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <div className='ScrollTotop'>
            <ToTopOutlined className='ScrollTotopBtn btnType5' onClick={scrollToTop} />
        </div>
    )
}

export default ScrollTotop

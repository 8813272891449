import React, { useEffect, useState } from 'react'
import './plans.css'
import Personal from './Personal/Personal'
import Family from './Family/Family'
import Company from './Company/Company'
import mark from '../../Assets/plans/mark-svgrepo-com.svg'
import { Link } from 'react-router-dom'

const Plans = () => {

    const [isAtTop, setIsAtTop] = useState(true);

    const handleScroll = () => {
        if (window.scrollY === 0) {
            setIsAtTop(true);
        } else {
            setIsAtTop(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='Plans bgGreyLight1'>
            <div className="inner__">
                <Personal mark={mark} isAtTop={isAtTop} />
                <Family mark={mark} isAtTop={isAtTop} />
                <Company mark={mark} isAtTop={isAtTop} />
            </div>

            <div className="inner_2 bgGreyLight1" >
                <div className="inner2__">
                    <div className="row_2_1">
                        <h2 className='headType2'><b>We provide healthcare  for everyone</b>. Let's discuss on the plan that fit's you</h2>
                    </div>
                    <div className="row_2_2">
                        <Link to="/get-started" className='btnType2' style={{ fontSize: "2.5rem" }}> Get Started </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Plans

function numberOfDaysBetweenTwoDates(date1, date2) {
  let d1 = new Date(date1);
  let d2 = new Date(date2);

  // Calculate the difference in milliseconds.
  var diffInMs = Math.abs(d1 - d2);
  // Convert back to days and return
  return Math.round(diffInMs / (1000 * 60 * 60 * 24));
}

function formatHumanReadableDate(date) {
  //   let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
  let options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
  };
  return new Date(date).toLocaleDateString("en-US", options);
}

function getFutureDate(date, numOfDays) {
  // Returns a new date object that is 'numOfDays' number of days in the future from  the given date.
  var day = new Date(date);
  day.setDate(day.getDate() + numOfDays);
  return {
    date: day.toLocaleDateString("en-US"),
    time: day.toLocaleTimeString(),
    humanReadable: formatHumanReadableDate(day),
  };
}

function getPreviousDate(date, numOfDays) {
  // Returns a new date object that is 'numOfDays' number of days in the future from  the given date.
  var day = new Date(date);
  day.setDate(day.getDate() - numOfDays);
  return {
    date: day.toLocaleDateString("en-US"),
    time: day.toLocaleTimeString(),
    humanReadable: formatHumanReadableDate(day),
  };
}

module.exports = {
  numberOfDaysBetweenTwoDates,
  formatHumanReadableDate,
  getFutureDate,
  getPreviousDate,
};

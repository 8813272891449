import { useContext } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
//
import { AppThemeContext } from "./Context/AppThemeContextProvider/AppThemeContextProvider";
//
import Home from "./Components/Home/Home";
import Login from "./Components/Login/Login";
import NotFound from "./Components/NotFound/NotFound";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import Plans from "./Components/Plans/Plans";
import FindPharmacy from "./Components/FindPharmacy/FindPharmacy";
import HospitalAffiliation from "./Components/HospitalAffiliation/HospitalAffiliation";
import Blog from "./Components/Blog/Blog";
import ScrollTotop from "./Components/ScrollTotop/ScrollTotop";
import SearchPage from "./Components/SearchPage/SearchPage";
import AutomaticScrollToTop from "./Components/ScrollTotop/AutomaticScrollToTop";
import GetInTouch from "./Components/GetInTouch/GetInTouch";
import SignUp from "./Components/SignUp/SignUp";
import DoctorLogin from "./Components/Login/DoctorLogin";
import PatientLogin from "./Components/Login/PatientLogin";

function App() {
  const location = useLocation();

  //
  //

  const { isDarkMode } = useContext(AppThemeContext);

  return (
    <div className={`${isDarkMode ? "app-dark" : "app-light"}`}>
      <AutomaticScrollToTop />
      <Navbar />
      {/* <SubNavBar /> */}
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />

          <Route path="/signup" element={<SignUp />} />

          <Route path="/schedule-appointment" element={<FindPharmacy />} />

          {/* <Route path="/find-pharmacy" element={<FindPharmacy />} /> */}
          <Route
            path="/hospital-affiliation"
            element={<HospitalAffiliation />}
          />
          <Route path="/search" element={<SearchPage />} />

          <Route path="/blog" element={<Blog />} />

          <Route path="/get-in-touch" element={<GetInTouch />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login/doctor" element={<DoctorLogin />} />
          <Route path="/login/patient" element={<PatientLogin />} />
          <Route path="/about" element={<Plans />} />

          <Route path="/plans" element={<Plans />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </AnimatePresence>

      <Footer />
      <ScrollTotop />
    </div>
  );
}

export default App;

import React, { useState } from 'react'
import './GetInTouch.css'
import { Link } from 'react-router-dom'
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
const { Option } = Select;




const GetInTouch = () => {

    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState('optional');
    const onRequiredTypeChange = ({ requiredMarkValue }) => {
        setRequiredMarkType(requiredMarkValue);
    };

    return (
        <div className='GetInTouch'>
            <div className="inner__">
                <div className="left__">
                    <h1 className="title__ headType1">Get in touch</h1>
                    <p className="description__">
                        Use our contact form for all information requests or contact us directly using the
                        contact information below. All information is treated with complete confidentiality
                        and in accordance with our <Link to='/data-protection-statement' className='data_statement'>data protection statement</Link>.
                    </p>
                    <div className="foot__">
                        <p className='headType2'>Feel free to get in touch with us via email or phone</p>
                        <p className='foot__x2' >
                            <span className="email" >
                                <MailOutlined />  &nbsp; info@neomerithealth.com
                            </span>
                            <span className="phone" >
                                <PhoneOutlined /> &nbsp; +234 (0) 1234567892
                            </span>
                        </p>
                    </div>
                </div>

                <div className="right__">
                    <Form
                        name='getInTouchForm'
                        form={form}
                        layout="vertical"
                        initialValues={{
                            requiredMarkValue: requiredMark,
                        }}
                        onValuesChange={onRequiredTypeChange}
                        requiredMark={requiredMark}
                    >
                        <div className="form_grp">
                            <div className="grid_x2">
                                <Form.Item label="First name" required tooltip="This is a required field">
                                    <Input placeholder="First name" />
                                </Form.Item>
                                <Form.Item
                                    label="Last name"
                                    required
                                    tooltip="This is a required field"
                                >
                                    <Input placeholder="Last name" />
                                </Form.Item>
                            </div>

                            <div className="grid_x2">
                                <Form.Item
                                    label="Email"
                                    required
                                    tooltip="This is a required field"
                                    rules={[
                                        {
                                            type: 'email',
                                        },
                                    ]}
                                >
                                    <Input placeholder="example@mail.com" />
                                </Form.Item>
                                <Form.Item
                                    label="Phone"
                                    required
                                    tooltip="This is a required field"

                                >
                                    <Input placeholder="Phone" />
                                </Form.Item>
                            </div>

                            <div className="grid_x2">
                                <Form.Item
                                    label="Company"
                                // tooltip="This is a required field"

                                >
                                    <Input placeholder="Comapany Name" />
                                </Form.Item>
                                <Form.Item
                                    label="Position"

                                >
                                    <Input placeholder="Position" />
                                </Form.Item>
                            </div>

                            <div className="grid_x2">
                                <Form.Item
                                    label="Message"
                                >
                                    <Input.TextArea placeholder="Message" />
                                </Form.Item>
                            </div>

                            <div className="grid_x2">
                                <Form.Item
                                // label="Message"
                                >
                                    <Select placeholder="What are you interested in ? (optional)">
                                        <Option value="personal_plan">Personal HealthCare plan</Option>
                                        <Option value="family_plan">Family HealthCare plan</Option>
                                        <Option value="company_plan">Company HealthCare plan</Option>
                                        <Option value="see_doctor">See a Doctor</Option>
                                        <Option value="see_nurse">See a Nurse</Option>
                                        <Option value="find_pharmacy">Find a Pharmacy</Option>
                                        <Option value="others">Others</Option>
                                    </Select>
                                </Form.Item>
                            </div>

                            {/* <Form.Item> */}
                            <Button type="primary" size='large' className='btnType6'>Submit</Button>
                            {/* </Form.Item> */}
                        </div>

                    </Form>
                </div>
            </div>
        </div>
    )
}

export default GetInTouch

import React from 'react'
import { Link } from 'react-router-dom'
import ScheduleAppointment_img from '../../../Assets/how-to/sched_doc.png'
import getNurse_image from '../../../Assets/how-to/get_nurse.png'
import nearbyPharmacy_icon from '../../../Assets/how-to/near_pharmacy.png'



const ServicesGrp = () => {
    return (
        <div className='ServicesGrp'>
            <div className="inner">
                <div className="ServicesGrp_item borderType1">
                    <div className="title_box">
                        <h2 className="bigText headType1">schedule appointment with doctor</h2>
                        <p className="smallText headType2">This is a brief description of how to schedule an appointment with a doctor</p>
                    </div>
                    <div className="image_box">
                        <img src={ScheduleAppointment_img} alt="" />
                    </div>
                    <Link to='/schedule-appointment' className='btnType4 action_btn' > login to Schedule An Appointment Now </Link>
                </div>

                <div className="ServicesGrp_item borderType1">
                    <div className="title_box">
                        <h2 className="bigText headType1">Get a nurse near you</h2>
                        <p className="smallText headType2">This is a brief description of how to find a nurse in your location.</p>
                    </div>
                    <div className="image_box">
                        <img src={getNurse_image} alt="" />
                    </div>
                    <Link to='/search?type=get-nurse' className='btnType4 action_btn' > Find a Nurse Now </Link>
                </div>

                <div className="ServicesGrp_item borderType1">
                    <div className="title_box">
                        <h2 className="bigText headType1"> Find nearby pharmacy</h2>
                        <p className="smallText headType2">This is a brief description of how to find a nearby pharmacy in your location.</p>
                    </div>

                    <div className="image_box">
                        <img src={nearbyPharmacy_icon} alt="" />
                    </div>

                    <Link to='/search?type=find-pharmacy' className='btnType4 action_btn' > Find a pharmacy Now </Link>
                </div>
            </div>
        </div>
    )
}

export default ServicesGrp

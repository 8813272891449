import React, { useContext } from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import {
    LinkedinOutlined,
    InstagramOutlined,
    FacebookOutlined,
    YoutubeOutlined,
} from '@ant-design/icons'
import logo1 from '../../Assets/logo/nmh-1.png'
import logo2 from '../../Assets/logo/nmh-2.png'
import { AppThemeContext } from '../../Context/AppThemeContextProvider/AppThemeContextProvider'

const Footer = () => {
    const { isDarkMode } = useContext(AppThemeContext)

    const footer_a_data = [
        {
            id: 1,
            title: "services",
            body: [
                {
                    label: "Find nearby pharmacy", url: "/search?type=find-pharmacy"
                },
                { label: "hospital Affiliation", url: "/hospital-affiliation" },
                { label: "home nursing care (get a nurse near you)", url: "/search?type=get-nurse" },
            ]
        },
        {
            id: 2,
            title: "For Patients",
            body: [
                { label: "Login", url: "/login" },
                { label: "SignUp", url: "/signup?type=PATIENT&step=1" },
                { label: "Bookings", url: "/login?rURL=patients/appointments" },
            ]
        },
        {
            id: 3,
            title: "For Doctors",
            body: [
                { label: "Login", url: "/login" },
                { label: "SignUp", url: "/signup?type=DOCTOR&step=1" },
                { label: "Appointments", url: "/login?rURL=doctors/appointments" },
            ]
        },
        {
            id: 4,
            title: "Resources",
            body: [
                { label: "Blog ", url: "/blog" },
            ]
        },
        {
            id: 5,
            title: "Company",
            body: [
                { label: "Home", url: "/" },
                { label: "about us", url: "/about" },
                { label: "Get in touch", url: "/get-in-touch" },
                // { label: "careers", url: "/careers" },
            ]
        },
        {
            id: 6,
            title: "plans",
            body: [
                { label: "personal", url: "/plans#personalPlan" },
                { label: "family", url: "/plans#familyPlan" },
                { label: "company", url: "/plans#companyPlan" },
            ]
        },
    ]

    return (
        <div className='Footer'>
            <div className="footer_a">
                <div className='logo-box'>
                    <Link to='/' >
                        <img src={isDarkMode ? logo2 : logo1} alt="" />
                    </Link>
                </div>
                {
                    footer_a_data.map((item, index) => (
                        <div className="footer_a_item" key={item.id}>
                            <h3 className='headType3'>{item.title}</h3>
                            {
                                item.body.map((child, index) => (
                                    <p key={child.label}> <Link to={child.url}>{child.label}</Link> </p>
                                ))
                            }
                        </div>
                    ))
                }

            </div>

            <div className="footer_b">
                <div className="footer_b_1">
                    <Link href={`/`} > Terms of use </Link>
                    <Link href={`/`} > Privacy policy</Link>
                    <span className="copyright"> &copy;2024. <b> Neomerit Metro Health.</b>  All rights reserved. </span>
                </div>
                <div className="footer_b_2">
                    <a href="http://nhm" className="social_link"> <LinkedinOutlined /> </a>
                    <a href="http://nhm" className="social_link"> <InstagramOutlined /> </a>
                    <a href="http://nhm" className="social_link"> <FacebookOutlined /> </a>
                    <a href="http://nhm" className="social_link"> <YoutubeOutlined /> </a>
                </div>
            </div>
        </div>
    )
}

export default Footer

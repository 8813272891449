import React, { createContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    numberOfDaysBetweenTwoDates,
    formatHumanReadableDate,
    getFutureDate,
    getPreviousDate,
} from './functions/dates'

import { formatCurrency } from './functions/formatCurrency'


export const SharedContext = createContext()

const SharedContextProvider = ({ children }) => {
    const navigate = useNavigate()

   

    const values = {
        navigate,
        // 
        numberOfDaysBetweenTwoDates,
        formatHumanReadableDate,
        getFutureDate,
        getPreviousDate,
        //
        formatCurrency,
        // 
     
    }
    return (
        <SharedContext.Provider value={values}>
            {children}
        </SharedContext.Provider>
    )
}

export default SharedContextProvider

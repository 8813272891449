import React from 'react'

const FindPharmacy = () => {
    return (
        <div>
            FindPharmacy
            FindPharmacy
        </div>
    )
}

export default FindPharmacy

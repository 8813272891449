import React from 'react'
import { Link } from 'react-router-dom'
import './login.css'
import patientIcon from '../../Assets/login/patient-svgrepo-com.svg'
import doctorIcon from '../../Assets/login/surgeon-doctor-svgrepo-com.svg'
import providerIcon from '../../Assets/login//hospital-svgrepo-com.svg'
import YouDontHaveAnAccountYet from '../YouDontHaveAnAccountYet/YouDontHaveAnAccountYet'

const Login = () => {
    return (
        <div className='Login_wrapper '>
            <div className="row_1">
                <h1 className='headType1'>Welcome to <span className="name">Neomerit Metro Health</span></h1>
                <p className='title headType2'>Please log in to your account as one of the following.</p>
                <div className="login_categories">
                    <div className="category borderType1">
                        <img src={patientIcon} alt="" />
                        <h3 className='headType3'>for patients</h3>
                        <p>Access your dashboard now to see doctors, schedule an appointment, and others</p>
                        <div className='link_box'>
                            <Link to="/login/patient" className='btnType3' style={{ fontSize: "1.5rem" }}> Login </Link>
                        </div>
                    </div>
                    <div className="category borderType1">
                        <img src={doctorIcon} alt="" />
                        <h3 className='headType3'>for Doctors</h3>
                        <p>Access your dashboard now to see Patients, attend to appointments, and others</p>
                        <div className='link_box'>
                            <Link to="/login/doctor" className='btnType3' style={{ fontSize: "1.5rem" }}> Login </Link>
                        </div>
                    </div>
                    <div className="category borderType1">
                        <img src={providerIcon} alt="" />
                        <h3 className='headType3'>for Health Providers</h3>
                        <p>Access your dashboard now to manage interactions, enquiries and more</p>
                        <div className='link_box'>
                            <Link to="/login/health-provider" className='btnType3' style={{ fontSize: "1.5rem" }}> Login </Link>
                        </div>
                    </div>
                </div>
            </div>

            <YouDontHaveAnAccountYet />
        </div>
    )
}

export default Login

import React from 'react'
import doctorPhoto from '../../../Assets/MeetOurSpecialists/doc2.jpg'
import { Carousel } from 'antd';




const MeetOurSpecialists = () => {
    const doctors = [
        {
            id: 1,
            doctorName: "Dr. Smith",
            specialty: "Cardiology",
            photo: doctorPhoto,
        },
        {
            id: 2,
            doctorName: "Dr. Johnson",
            specialty: "Pediatrics",
            photo: doctorPhoto,
        },
        {
            id: 3,
            doctorName: "Dr. Lee",
            specialty: "Dermatology",
            photo: doctorPhoto,
        },
        {
            id: 4,
            doctorName: "Dr. Patel",
            specialty: "Oncology",
            photo: doctorPhoto,
        },
        {
            id: 5,
            doctorName: "Dr. Garcia",
            specialty: "Neurology",
            photo: doctorPhoto,
        },
        {
            id: 6,
            doctorName: "Dr. Kim",
            specialty: "Orthopedics",
            photo: doctorPhoto,
        },
        // {
        //     id: 7,
        //     doctorName: "Dr. Adams",
        //     specialty: "Psychiatry",
        //     photo: doctorPhoto,
        // },
        // {
        //     id: 8,
        //     doctorName: "Dr. Wang",
        //     specialty: "Internal Medicine",
        //     photo: doctorPhoto,
        // },
    ];

    return (
        <div className='MeetOurSpecialists'>
            <div className="inner__">
                <div className="inner_head ">
                    <h1 className='headType1'>Meet Our Specialists</h1>
                </div>
                <div className="inner_body_wrapper">

                    <div className="inner_body">
                        {
                            doctors.map((item, index) => (
                                <div className="body_item" key={item.id}>
                                    <div className="image_container">
                                        <img src={item.photo} alt="" />
                                    </div>
                                    <div className="name__ textType1">{item.doctorName}</div>
                                    <div className="specialty__ textType2"> {item.specialty} </div>
                                </div>
                            ))
                        }
                    </div>

                    <Carousel autoplay className='automatic__slider'>
                        {
                            doctors.map((item, index) => (
                                <div className="body_item" key={item.id}>
                                    <div className="image_container">
                                        <img src={item.photo} alt="" />
                                    </div>
                                    <div className="name__ textType1">{item.doctorName}</div>
                                    <div className="specialty__ textType2"> {item.specialty} </div>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </div>
        </div>
    )
}

export default MeetOurSpecialists

import React from 'react'

const HospitalAffiliation = () => {
    return (
        <div>
            HospitalAffiliation
            HospitalAffiliation
        </div>
    )
}

export default HospitalAffiliation

import React from 'react'
import mark from '../../../Assets/plans/mark-svgrepo-com.svg'


const WhyYouShouldChooseUs = () => {
    return (
        <div className='WhyYouShouldChooseUs bgGreyLight1'>
            <div className="inner__">

                <h1 className="title headType1">Why You Should Choose Us</h1>
                {/* <p className="headType2">We provide healthcare for everyone</p> */}
                <br />
                <div className="body ">
                    <ul>
                        <li className=' headType2'  >  <img src={mark} alt="" className='icon headType2' />  we provide the best health services in your location </li>
                        <li className=' headType2'  >  <img src={mark} alt="" className='icon headType2' />  we are rated 5star in nigeria </li>
                        <li className=' headType2'  >  <img src={mark} alt="" className='icon headType2' />  we provide the best health services in your location </li>
                        <li className=' headType2'  >  <img src={mark} alt="" className='icon headType2' />  we provide the best health services in your location </li>
                        <li className=' headType2'  >  <img src={mark} alt="" className='icon headType2' />  we provide the best health services in your location </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default WhyYouShouldChooseUs

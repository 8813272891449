import React from 'react'
import { Link } from 'react-router-dom'
import './YouDontHaveAnAccountYet.css'

const YouDontHaveAnAccountYet = () => {
    return (
        <div className="YouDontHaveAnAccountYet bgGreyLight1 " >
            <div className="inner__">
                <div className="row_2_1">
                    <h2 className='headType1'>You don’t have an account yet?</h2>
                    <p>Get in touch with us for more information </p>
                </div>
                <div className="row_2_2">
                    <Link to="/get-started" className='btnType2' style={{ fontSize: "2.5rem" }}> Get Started </Link>
                </div>
            </div>
        </div>
    )
}

export default YouDontHaveAnAccountYet

import React, { createContext, useState } from 'react'

export const AppThemeContext = createContext()

const AppThemeContextProvider = ({ children }) => {

  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  /**
   * 
   */

  const values = {
    isDarkMode,
    toggleTheme
  }
  return (
    <AppThemeContext.Provider value={values}>
      {children}
    </AppThemeContext.Provider>
  )
}

export default AppThemeContextProvider


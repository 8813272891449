import React from 'react'

const Personal = ({ mark, isAtTop }) => {
    return (
        <div className='personal ' id='personalPlan'>
            <h1 className={`${isAtTop ? 'headType1' : 'headType1_'}`}>Personal plan</h1>
            <p className="headType2">Suitable for Individuals</p>
            <div className="body borderType1">
                <ul>
                    <li>  <img src={mark} alt="" />  cvbnbv </li>
                    <li>   <img src={mark} alt="" />  cvbnbvn</li>
                    <li>   <img src={mark} alt="" /> cvbnvbn </li>
                    <li>   <img src={mark} alt="" /> cvbnbvn </li>
                    <li>   <img src={mark} alt="" /> cvbnvbn </li>
                    <li>   <img src={mark} alt="" /> cvbnbvn </li>
                    <li>   <img src={mark} alt="" /> cvbnb </li>
                    <li>   <img src={mark} alt="" /> cvbnvbn </li>
                </ul>
            </div>
        </div>
    )
}

export default Personal
